import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 5×2\\@90% 1RM`}</p>
    <p>{`Overhead Band Pull Aparts 5×8`}</p>
    <p>{`then, `}<strong parentName="p">{`“Karabel”`}</strong></p>
    <p>{`10 Rounds for time of:`}</p>
    <p>{`3-Power Snatch (135/95)`}</p>
    <p>{`15-Wall Balls (20/14)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      